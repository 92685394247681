<template>
  <div>
    <home-landing />
    <home-features />
    <home-eth-blocks />
    <home-why-mew />
    <app-partners-block />
    <app-get-started />
  </div>
</template>

<script>
export default {
  name: 'TheHomeLayout',
  components: {
    HomeLanding: () => import('../components-default/HomeLanding'),
    HomeFeatures: () => import('../components-default/HomeFeatures'),
    HomeEthBlocks: () => import('../components-default/HomeEthBlocks'),
    HomeWhyMew: () => import('../components-default/HomeWhyMew'),
    AppGetStarted: () => import('@/core/components/AppGetStarted'),
    AppPartnersBlock: () => import('@/core/components/AppPartnersBlock')
  }
};
</script>
